import React, { useState } from 'react';
import Header from './Header';
import Footer from './Footer';

const Contact_us = () => {

    return (
        <>
            {/* Header */}
            <Header page_name={"Contact"}/>

            {/* About Us */}
            <div className='custom-padding-top'>
                <div className='container'>
                    <div className='row'>
                        <div className='px-3'>
                            <div className='About_us_content'>
                                <h2>Contact us</h2>
                                <hr />
                                <form className='custom-form'>
                                    <div className='d-flex flex-wrap'>
                                        <div className="mb-3 col-12 col-md-6 px-2">
                                            <input type="text" className="form-control" placeholder='Enter Your Name' />
                                        </div>
                                        <div className="mb-3 col-12 col-md-6 px-2">
                                            <input type="text" className="form-control" placeholder='Enter Your Email' />
                                        </div>
                                        <div className="mb-3 col-12 px-2">
                                            <input type="text" className="form-control" placeholder='Enter Your Subject' />
                                        </div>
                                        <div className="mb-3 col-12 px-2">
                                            <textarea className='col-12 form-control' placeholder='Enter Your Problem'></textarea>
                                        </div>
                                    </div>
                                    <div className='col-12 text-end'>
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Footer */}
            <Footer />
        </>
    )
}

export default Contact_us;
