import React, { useState } from 'react';
import '../App_Info.css';
const App_Info_Page = () => {
    return (
        <>
            {/* Css */}
            <link rel="stylesheet" href="/assets/vehicle_info/css/bootstrap.min.css" />

            {/* Hero Section */}
            <div className='App_Info_Hero_Section'>
                <div className="container">
                    <div className='row align-items-center'>
                        <div className='App_Info_Hero_Box position-relative'>
                            <div className="Hero_Box_Video_Section">
                                <video src='/assets/vehicle_info/video/bc_game.mp4' autoPlay loop muted />
                                <div className='video-overlay'></div>
                            </div>
                            <div className='Hero_Box_Content_Section text-light'>
                                <div className='d-none d-md-block'>
                                    <h1>BC.GAME</h1>
                                    <h4>BlockDance B.V.</h4>
                                    <p>Verified by App</p>
                                    <div className='Logo-Box-Wrapper d-flex'>
                                        <div className='logo-box'>
                                            <img src='/assets/vehicle_info/images/bc_game_logo.png' />
                                        </div>
                                        <div className='information-list'>
                                            <ul>
                                                <li>
                                                    <strong>4.7<i className="bi bi-star-fill"></i></strong>
                                                    <p>1000K reviews</p>
                                                </li>
                                                <li>
                                                    <div className='custom-border'></div>
                                                    <strong>100M +</strong>
                                                    <p>Downloads</p>
                                                </li>
                                                <li>
                                                    <div className='custom-border'></div>
                                                    <img src='/assets/vehicle_info/images/e.svg' />
                                                    <p>Everyone</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-between align-items-end'>
                                        <div className='d-flex flex-wrap align-items-center pt-3 hero-section-btns-box'>
                                            <button className='custom-btn me-4'>Install</button>
                                            <p><span><i className="bi bi-share-fill"></i></span> Share</p>
                                            <p><span><i className="bi bi-bookmark-plus"></i></span> Add to wishlist</p>
                                        </div>
                                        <button className='custom-btn-2'><i className="bi bi-play"></i>Trailer</button>
                                    </div>
                                </div>


                                <div className='responsive-hero-box d-flex flex-wrap d-md-none'>
                                    <div className='col-12 d-flex'>
                                        <div className='logo-box me-3'>
                                            <img src='/assets/vehicle_info/images/bc_game_logo.png' />
                                        </div>
                                        <div>
                                            <h6 className='m-0'>BC.GAME</h6>
                                            <p className='m-0'>
                                                <span className='primary-text-color'>BlockDance B.V.</span>
                                                <br />
                                                Verified by App
                                            </p>
                                        </div>
                                    </div>
                                    <div className='information-list col-12 pt-3'>
                                        <ul>
                                            <li>
                                                <strong>4.7<i className="bi bi-star-fill"></i></strong>
                                                <p>1000K reviews</p>
                                            </li>
                                            <li>
                                                <div className='custom-border'></div>
                                                <strong>100M +</strong>
                                                <p>Downloads</p>
                                            </li>
                                            <li>
                                                <div className='custom-border'></div>
                                                <img src='/assets/vehicle_info/images/e.svg' />
                                                <p>Everyone</p>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='d-flex flex-wrap align-items-center justify-content-center pt-3 hero-section-btns-box text-center w-100'>
                                        <button className='custom-btn col-12'>Install</button>
                                        <div className='text-center py-2'>
                                            <p className='d-inline'><span><i className="bi bi-share-fill"></i></span> Share</p>
                                            <p className='d-inline'><span><i className="bi bi-bookmark-plus"></i></span> Add to wishlist</p>
                                        </div>
                                    </div>
                                    <button className='custom-btn-2 hero-btn-responsive'><i className="bi bi-play"></i>Trailer</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Banner Images */}
            <div className='py-20'>
                <div className="container">
                    <div className="row align-items-center">
                        <ul className='banner-img-wrapper overflow-x-scroll'>
                            <li className='col-12'>
                                <img src='/assets/vehicle_info/images/screenshot-1.png' />
                            </li>
                            <li className='col-12'>
                                <img src='/assets/vehicle_info/images/screenshot-2.png' />
                            </li>
                            <li className='col-12'>
                                <img src='/assets/vehicle_info/images/screenshot-3.png' />
                            </li>
                            <li className='col-12'>
                                <img src='/assets/vehicle_info/images/screenshot-4.png' />
                            </li>
                            <li className='col-12'>
                                <img src='/assets/vehicle_info/images/screenshot-5.png' />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            {/* About This App */}
            <div className='py-20'>
                <div className="container">
                    <div className='row'>
                        <div className='App_Details_Content'>
                            <h2>About this app</h2>
                            <p>
                                Mobile gaming redefined. Experience a diverse library of captivating games, from timeless classics to cutting-edge innovations. Enjoy a user-friendly interface, secure transactions, and a thriving gaming community.
                                <br />
                                <br />
                                Download BC.Game now on Google Play and dive into the fun!
                            </p>
                            <div className='Date-Box'>
                                <h6 className='text-dark'>Updated on</h6>
                                <p>Mar 27, 2024</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* APP Features */}
            <div className='py-20'>
                <div className="container">
                    <div className='row'>
                        <div className='App_Details_Content'>
                            <h2>APP Features</h2>
                            <ul className='App_Details_List ps-3'>
                                <li>Wide variety of games: Classics, New releases, and Exclusive titles.</li>
                                <li>Seamless gameplay.</li>
                                <li>Stunning graphics.</li>
                                <li>User-friendly mobile interface.</li>
                                <li>Secure and fast transactions.</li>
                                <li>Active gaming community.</li>
                                <li>Daily rewards, thrilling challenges, and exclusive bonuses.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            {/* Data safety */}
            <div className='py-20'>
                <div className="container">
                    <div className='row'>
                        <div className='App_Details_Content'>
                            <h2>Data safety</h2>
                            <p>Safety starts with understanding how developers collect and share your data. Data privacy and security practices may vary based on your use, region, and age. The developer provided this information and may update it over time.</p>
                            <div className='data-safety-list'>
                                <ul>
                                    <li>
                                        <span><i className="bi bi-share"></i></span>
                                        <div>
                                            No data shared with third parties
                                            <p>Learn more about how developers declare sharing</p>
                                        </div>
                                    </li>
                                    <li>
                                        <span><i className="bi bi-cloud-arrow-up"></i></span>
                                        <div>
                                            This app may collect these data types
                                            <p>Location, Personal info and 5 others</p>
                                        </div>
                                    </li>
                                    <li>
                                        <span><i className="bi bi-lock"></i></span>
                                        <div>
                                            Data is encrypted in transit
                                        </div>
                                    </li>
                                    <li>
                                        <span><i className="bi bi-trash3"></i></span>
                                        <div>
                                            You can request that data be deleted
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Js */}
            <script src="/assets/vehicle_info/js/jquery-3.7.1.min.js"></script>
            <script src="/assets/vehicle_info/js/bootstrap.bundle.min.js"></script>
        </>
    )
}

export default App_Info_Page;
