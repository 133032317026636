import React from 'react';
import "./App.css";
import { Route, Routes } from 'react-router-dom';
import ShortContainer from "./components/ShortContainer";
import UnderMaintenence from "./components/UnderMaintenence";
import WebCall from "./components/WebCall";
import Success from "./components/Success";
import Failed from "./components/Failed";
import Vehicle_Info_Home from "./components/vehicle_info/Home";
import Vehicle_Info_About_us from "./components/vehicle_info/About_us";
import Vehicle_Info_Policy from "./components/vehicle_info/Policy";
import Vehicle_Info_Contact_us from "./components/vehicle_info/Contact_us";
import App_Info_Page from "./components/App_Info_Page";
import AgoraRTC from 'agora-rtc-sdk-ng';
import Vehicle_details from './components/vehicle_info/Vehicle_details';
import CallNow from './components/CallNow';


const client = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' });

function App() {
  return (
    <Routes>
      <Route exact path={'/'} element={<Vehicle_Info_Home />} />
      <Route exact path={'/about-us'} element={<Vehicle_Info_About_us />} />
      <Route exact path={'/policy'} element={<Vehicle_Info_Policy />} />
      <Route exact path={'/contact'} element={<Vehicle_Info_Contact_us />} />
      <Route exact path={'/vehicle-details/:number'} element={<Vehicle_details />} />
      <Route exact path={'/App-info-page'} element={<App_Info_Page />} />
      <Route exact path={'/UnderMaintenence'} element={<UnderMaintenence />} />
      <Route exact path={'/view-shorts'} element={<ShortContainer />} />
      <Route exact path={'/view-shorts/continue'} element={<ShortContainer />} />
      {/* <Route exact path={'/call'} element={<WebCall client={client} userId={userId} setUserId={setUserId} />} /> */}
      <Route exact path={'/call'} element={<WebCall client={client} />} />
      <Route exact path={'/success'} element={<Success />} />
      <Route exact path={'/failed'} element={<Failed />} />
      <Route exact path={'/call-now'} element={<CallNow />} />
    </Routes>
  );
}

export default App;
