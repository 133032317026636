import React, { useState } from 'react';
import Header from './Header';
import Footer from './Footer';

const Policy = () => {

    return (
        <>
            {/* Header */}
            <Header page_name={"Policy"} />

            {/* About Us */}
            <div className='custom-padding-top'>
                <div className='container'>
                    <div className='row'>
                        <div className='px-3'>
                            <div className='About_us_content'>
                                <h2>Privacy Policy</h2>
                                <hr />
                                <div className='About_us_content_box'>
                                    <p>
                                        Google Privacy Policy
                                        <br />
                                        When you use our services, you’re trusting us with your information. We understand this is a big responsibility and work hard to protect your information and put you in control.
                                        <br />
                                        <br />

                                        This Privacy Policy is meant to help you understand what information we collect, why we collect it, and how you can update, manage, export, and delete your information.

                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Footer */}
            <Footer />
        </>
    )
}

export default Policy;
