import React, { useEffect, useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import axios from 'axios';
import { json, useNavigate, useParams } from 'react-router-dom';

const Vehicle_details = () => {
    const { number } = useParams();
    const [VehicleData, setVehicleData] = useState({});
    const [loading, setloading] = useState(true);
    let Navigator = useNavigate();
    useEffect(() => {
        // let config = {
        //     method: 'get',
        //     maxBodyLength: Infinity ,
        //     mode: 'cors',
        //     url: `https://minglegames.xyz/rc_info/get_info.php?vehicle_no=${number}`,
        //     // headers: {}
        // };
        // axios.request(config)
        //     .then((response) => {
        //         let data = response.data;
        //         if (data.pageProps.rcDetailsResponse.errors) {
        //             alert('Vehicle not found');
        //             Navigator('/');
        //         } else {
        //             setVehicleData({
        //                 image: data.pageProps.rcDetailsResponse.data.webSections[0].imageUrl || '',
        //                 number: data.pageProps.rcDetailsResponse.data.webSections[0].messages[0].subtitle || '',
        //                 registered_rto: data.pageProps.rcDetailsResponse.data.webSections[0].messages[1].subtitle || '',
        //                 city: data.pageProps.rcDetailsResponse.data.webSections[0].messages[2].subtitle || '',
        //                 state: data.pageProps.rcDetailsResponse.data.webSections[0].messages[3].subtitle || '',
        //                 rto_phone_number: data.pageProps.rcDetailsResponse.data.webSections[0].messages[4].subtitle || '',
        //                 owner_name: data.pageProps.rcDetailsResponse.data.webSections[0].message.title || '',
        //                 maker: data.pageProps.rcDetailsResponse.data.webSections[0].message.subtitle || ''
        //             });
        //         }
        //         setTimeout(() => {
        //             setloading(false);
        //         }, 2000)
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //     });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            // url: 'http://localhost:9000/api/Check_Vehicle_details',
            url: 'https://sursuri-back-end.vercel.app/api/Check_Vehicle_details',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Cookie': 'connect.sid=s%3AiAQLkz4Mwpbfk-Phqb6XwgyPUJU11hm3.5A7VowBtaO5WYRSkOXhYImS%2FEGl%2BgKZyjaRkT1lsUzw'
            },
            data: { 'vehicle_number': number }
        };

        axios.request(config)
            .then((response) => {
                const decodedString = atob(response.data.data.vehicle_data);
                setVehicleData(JSON.parse(decodedString) || []);
                setloading(false);
            })
            .catch((error) => {
                console.log(error);
            });

    }, []);
    return (
        <>
            {/* Header */}
            <Header page_name={"About"} />

            {loading == true ? <div className="loader-box">
                <div id="loader-2"><span></span><span></span><span></span></div>
            </div> : ''}



            {/* Vehicle Info */}
            <div className='custom-padding-top'>
                <div className='pb-5'>
                    <div className='container'>
                        <div className='row'>
                            <div className='vehicle-details-main-box d-flex flex-wrap flex-column-reverse flex-md-row'>
                                <div className='vehicle-details-title-box d-none d-md-block'>
                                    <h3>RTO Details</h3>
                                </div>
                                <div className='right-side-title d-flex d-none d-md-block justify-content-center align-items-center'>
                                    <p><img src='/assets/vehicle_info/images/india_img.svg' />{VehicleData.license_plate}</p>
                                </div>

                                <div className='col-12 col-md-7'>
                                    <table className='w-100'>
                                        <tr className='custom-tr'>
                                            <td className='key_name_td'> <p className='key_name'>Number</p> </td>
                                            <td className='key_value_td'> <p className='key_value'>{VehicleData.license_plate}</p> </td>
                                        </tr>
                                        <tr className='custom-tr'>
                                            <td className='key_name_td'> <p className='key_name'>Chassis Number</p> </td>
                                            <td className='key_value_td'> <p className='key_value'>{VehicleData.chassis_number}</p> </td>
                                        </tr>
                                        <tr className='custom-tr'>
                                            <td className='key_name_td'> <p className='key_name'>Engine Number</p> </td>
                                            <td className='key_value_td'> <p className='key_value'>{VehicleData.engine_number}</p> </td>
                                        </tr>
                                        <tr className='custom-tr'>
                                            <td className='key_name_td'> <p className='key_name'>Financer</p> </td>
                                            <td className='key_value_td'> <p className='key_value'>{VehicleData.financer}</p> </td>
                                        </tr>
                                        <tr className='custom-tr'>
                                            <td className='key_name_td'> <p className='key_name'>Insurance Company</p> </td>
                                            <td className='key_value_td'> <p className='key_value'>{VehicleData.insurance_company}</p> </td>
                                        </tr>
                                        <tr className='custom-tr'>
                                            <td className='key_name_td'> <p className='key_name'>Insurance Expiry</p> </td>
                                            <td className='key_value_td'> <p className='key_value'>{VehicleData.insurance_expiry}</p> </td>
                                        </tr>
                                        <tr className='custom-tr'>
                                            <td className='key_name_td'> <p className='key_name'>RC Status</p> </td>
                                            <td className='key_value_td'> <p className='key_value'>{VehicleData.rc_status}</p> </td>
                                        </tr>
                                    </table>

                                    <button className='custom-btn-2 mt-3'>View More</button>

                                </div>

                                <div className='col-12 col-md-5 vehicle-details-img-section justify-content-center d-flex'>
                                    <div className='col-12 col-lg-8'>
                                        <img src={VehicleData.image} />
                                        <div className='owner-details'>
                                            <p><span>Owner Name : </span>{VehicleData.owner_name}</p>
                                            <hr className='opacity-100' />
                                            <p><span>Maker :</span> {VehicleData.brand_name}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-12 d-md-none'>
                                    <div className='vehicle-details-title-box d-md-none position-relative'>
                                        <h3>RTO Details</h3>
                                    </div>
                                    <div className='right-side-title d-flex position-relative justify-content-center align-items-center'>
                                        <p><img src='/assets/vehicle_info/images/india_img.svg' />{VehicleData.license_plate}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Footer */}
            <Footer />
        </>
    )
}

export default Vehicle_details;
