import React from 'react';

const Footer = () => {
    return (
        <>
            <footer className="footer-main-box-wrapper mt-5">
                <div className="container">
                    <div className="row py-4 text-center text-md-start">
                        <div className="col-12 col-md-8">
                            <h2>Download Our App</h2>
                            <p className="text-color-primary">Download the app and get every vehicle on your tips.</p>
                            <div className="footer-download-app-img-box d-flex flex-wrap">
                                <div className="col-lg-3  col-12">
                                    <h4 className="text-color-primary">Overall Rating</h4>
                                    <h2>4.5 <span><i className="bi bi-star-fill"></i></span></h2>
                                </div>
                                <div className="col-lg-9  col-12 pt-md-1 pt-lg-0">
                                    <img src="/assets/vehicle_info/images/app.svg" alt="" />
                                    <img src="/assets/vehicle_info/images/playStore.svg" alt="" />
                                </div>
                            </div>
                            <p> <span><i className="bi bi-envelope-fill"></i></span> rtohelp2024@gmail.com</p>
                        </div>
                        <div className="d-none d-md-block col-md-4 text-end">
                            <img src="/assets/vehicle_info/images/footer.png" alt="" />
                        </div>
                    </div>
                    <div className="row text-center mt-3">
                        <p>All rights reserved ©2024 Rto Vehicle Information.</p>
                    </div>
                </div>
            </footer>

            <script src="/assets/vehicle_info/js/jquery-3.7.1.min.js"></script>
            <script src="/assets/vehicle_info/js/bootstrap.bundle.min.js"></script>
        </>
    )
}

export default Footer;