import React from 'react';

const Failed = () => {
  return (
    <div className='Under_Maintenence'>
      <h1>Failed </h1>
      <br/>
      <h3>Your Payment is Failed.</h3>
    </div>
  )
}

export default Failed;
