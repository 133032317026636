import React from 'react';
import Header from './Header';
import Footer from './Footer';

const About_us = () => {
    return (
        <>
            {/* Header */}
            <Header page_name={"About"} />

            {/* About Us */}
            <div className='custom-padding-top'>
                <div className='container'>
                    <div className='row'>
                        <div className='px-3'>
                            <div className='About_us_content'>
                                <h2>About</h2>
                                <hr />
                                <div className='About_us_content_box'>
                                    <p>
                                        An About Us page is an integral piece of content to have on your website.
                                        <br />

                                        Every single successful business has one, no matter their industry or what they sell.
                                        <br />

                                        After all, every brand has a story – and your About Us page helps you tell yours.
                                        <br />

                                        That’s important because, these days, story and connection matter more than ever to customers.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Footer */}
            <Footer />
        </>
    )
}

export default About_us;
