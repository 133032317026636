import React from 'react';

const Success = () => {
  return (
    <div className='Under_Maintenence'>
      <h1>Success </h1>
      <br/>
      <h3>Your Payment is Successfully Completed.</h3>
    </div>
  )
}

export default Success;
