import React, { useEffect, useRef, useState, useCallback } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Mousewheel } from 'swiper/modules'
import { Suspense, lazy } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import $ from 'jquery'
import { API_BASE_URL } from '../config';
const Short = lazy(() => import('./Short'));
const qs = require('qs');

function CallNow() {
  const shortContainerRef = useRef();
  const [data, setData] = useState([]);
  const [ModalData, setModalData] = useState({});
  const [gameData, setgameData] = useState({});
  const [converted_id, setconverted_id] = useState();
  const [loadedIndexes, setLoadedIndexes] = useState([]);
  const [IsLoading, setIsLoading] = useState(true);
  const [ModalClosePermission, setModalClosePermission] = useState(false);
  const [AppLinkLocalData, setAppLinkLocalData] = useState([]);
  const [deviceId, setDeviceId] = useState(null);
  const swiperRef = useRef(null);
  const navigate = useNavigate();
  const currentURL = window.location.search;
  const currentFullURL = window.location.href;
  const searchParams = new URLSearchParams(currentURL);
  const location = useLocation();
  let key = searchParams.get("key");
  let isDefault = 1;
  let mid = searchParams.get("mid");
  const [currentMid, setCurrentMid] = useState(localStorage.getItem('mid') || null);

  if (localStorage.getItem('back')) {
    localStorage.setItem('callJoined', true);
  }

  useEffect(() => {
    console.log(location.pathname);
    if (localStorage.getItem('back')) {
      localStorage.removeItem('back');
      if (localStorage.getItem('callStarted') == 'true') {
        localStorage.removeItem('callStarted');
        localStorage.removeItem('payment');
        localStorage.removeItem('waiting');
        localStorage.removeItem('startTime');
        localStorage.removeItem('callJoined');
        localStorage.removeItem('calling');
      }
    }
    let position = currentFullURL.search("/continue?");
    if (position > 0 && currentMid == mid) {
      localStorage.removeItem('Modal');
      document.getElementById('closeModelButton').click();
    }
  }, [location]);


  useEffect(() => {
    if (isDefault && key) {
      let videoids = localStorage.getItem('videoids') != null ? JSON.parse(localStorage.getItem('videoids')) : [];
      let data = qs.stringify({
        'filter': videoids,
        'limit': '2'
      });

      // let config = {
      //   method: 'post',
      //   maxBodyLength: Infinity,
      //   url: 'https://tik-tok.porn/api/new-videos',
      //   headers: {
      //     'Content-Type': 'application/x-www-form-urlencoded',
      //     'Cookie': 'XSRF-TOKEN=eyJpdiI6IklBS1BRdjh3M2thMnpSZ0xRWXhxOGc9PSIsInZhbHVlIjoiZFJ1dGg3TlB0V1VGRUh5RUliM2paYnI4RkJqdU9ZNlFvVG1pV3krUWJ3aVZmT0JLZFZxaW5mYTNlT25OYkdmT3cyZ2tVcHF6UUcvekRtclJha0g2L2Z4RHMyYVFhM01TNHhxU0FIcFlTUHZONjVKSDkrSWxZZC9MVGFFZ2pGWEsiLCJtYWMiOiI1YWU1NGU5NjQ3ZDQ4OTBkMzQwZDU3YWY4ZGI5M2ZhMTgyODY4MmNkZjhhZjg5ZTcxMDQ0ZDE2MTY2YzQwNmQ1IiwidGFnIjoiIn0%3D; tik_tokporn_session=eyJpdiI6Im5JR2llL3VGS2l0ZVV0QXJ0QS9nRUE9PSIsInZhbHVlIjoiRGl2aVZ6RWJiMkVGdStuUTJQT2tjeU1WTWRTbTBGdFRTR240K2VHeWhWYWZBZWc5SmI0V0hFQTluYlZ2OCtuVUduQmozWDhqTmFzMlFucVJMVVBrRzhWVHNGUWdieWY4R0xldjFWRDhWL2Vsa3AxbmF5dWlLcHRZZTdxaGR6UUEiLCJtYWMiOiJkYTA0NDhhN2IzYWNmMmJkYWYyYTMxMGU3NjNmODJhZTEwMjU2YWFlN2FlMDhlNDcxYTM1MGFlMzllNjYzMTMwIiwidGFnIjoiIn0%3D'
      //   },
      //   data: data
      // };

      // axios.request(config).then((response) => {
      //   let results = response.data;
      //   let master = [];
      //   for (const result of results) {
      //     let obj = {
      //       id: result.video_id,
      //       username: result.username,
      //       videoUrl: result.url,
      //       isFollowed: false,
      //       title: "✨✨✨🌈😍🌈✨✨✨ :- 115",
      //       profileUrl: "https://avatars.githubusercontent.com/u/69384657?v=4",
      //       reaction: {
      //         "likes": `${result.likes}`,
      //         "comments": "1151K",
      //         "isLiked": true
      //       }
      //     }
      //     master.push(obj);
      //     videoids.push(result.video_id)
      //   }
      //   setData(master);
      //   setIsLoading(false);
      //   localStorage.setItem('videoids', JSON.stringify(videoids))
      // }).catch((error) => {
      //   console.log(error);
      // });
      axios.get(`${API_BASE_URL}api/video/GetVideoData?key=${key}`).then(response => {
        let result = response.data.data;
        var inputString = response.data.AdminData.app_link;
        var newValue = response.data.converted_id;
        var replacedString = inputString.replace(/\$\{converted_id\}/g, newValue);
        replacedString = replacedString.replace(/\$\{gameData.package_name\}/g, response.data.gameData.package_name);
        // replacedString = replacedString.replace(/\$\{converted_mid\}/g, response.data.converted_mid);
        if (currentMid == mid) {
          replacedString = replacedString.replace(/\$\{converted_mid\}/g, response.data.converted_mid);
          localStorage.setItem('mid', response.data.converted_mid);
        } else {
          if (currentMid == '' || currentMid == undefined || currentMid == null) {
            localStorage.setItem('mid', response.data.converted_mid);
            replacedString = replacedString.replace(/\$\{converted_mid\}/g, response.data.converted_mid);
          } else {
            replacedString = replacedString.replace(/\$\{converted_mid\}/g, currentMid);
          }
        }
        response.data.AdminData.app_link = replacedString;
        // if (localStorage.getItem('Modal') != 'Locked') {
        if (currentMid == mid) {
          localStorage.setItem('mid', response.data.converted_mid);
        }
        setModalData(response.data.AdminData);
        setconverted_id(response.data.converted_id);
        setgameData(response.data.gameData);
        setAppLinkLocalData(response.data.App_Link_Data);
      }).catch(error => {
        console.error(error);
      });
    } else {
      if (key) {
        axios.get(`${API_BASE_URL}api/video/GetVideoData?key=${key}`)
          .then(response => {
            let result = response.data.data;
            var inputString = response.data.AdminData.app_link;
            var newValue = response.data.converted_id;
            var midValue = response.data.converted_mid;
            var replacedString = inputString.replace(/\$\{converted_id\}/g, newValue);
            var replacedString1 = replacedString.replace(/\$\{gameData.package_name\}/g, response.data.gameData.package_name);
            var replacedString2 = replacedString1.replace(/\$\{converted_mid\}/g, midValue);
            if (currentMid == mid) {
              replacedString2 = replacedString1.replace(/\$\{converted_mid\}/g, response.data.converted_mid);
              localStorage.setItem('mid', response.data.converted_mid);
            } else {
              if (currentMid == '' || currentMid == undefined || currentMid == null) {
                localStorage.setItem('mid', response.data.converted_mid);
                replacedString2 = replacedString1.replace(/\$\{converted_mid\}/g, response.data.converted_mid);
              } else {
                replacedString2 = replacedString1.replace(/\$\{converted_mid\}/g, currentMid);
              }
            }
            response.data.AdminData.app_link = replacedString2;
            // if (localStorage.getItem('Modal') != 'Locked') {
            if (currentMid == mid) {
              localStorage.setItem('mid', response.data.converted_mid);
            }
            setData(result);
            setModalData(response.data.AdminData);
            setconverted_id(response.data.converted_id);
            setgameData(response.data.gameData);
            setAppLinkLocalData(response.data.App_Link_Data);
            setIsLoading(false);
            let ids = [];
            for (const key in result) {
              ids.push(result[key].id)
            }
            localStorage.setItem('data', JSON.stringify(ids))
          })
          .catch(error => {
            console.error(error);
          });
      }
      else {
        axios.get(`${API_BASE_URL}api/video/GetVideoData`)
          .then(response => {
            let result = response.data.data;
            var inputString = response.data.AdminData.app_link;
            var newValue = response.data.converted_id;
            // console.log(response.data)

            var replacedString = inputString.replace(/\$\{converted_id\}/g, newValue);
            replacedString = replacedString.replace(/\$\{gameData.package_name\}/g, response.data.gameData.package_name);
            // replacedString = replacedString.replace(/\$\{converted_mid\}/g, response.data.converted_mid);
            if (currentMid == mid) {
              replacedString = replacedString.replace(/\$\{converted_mid\}/g, response.data.converted_mid);
              localStorage.setItem('mid', response.data.converted_mid);
            } else {
              if (currentMid == '' || currentMid == undefined || currentMid == null) {
                localStorage.setItem('mid', response.data.converted_mid);
                replacedString = replacedString.replace(/\$\{converted_mid\}/g, response.data.converted_mid);
              } else {
                replacedString = replacedString.replace(/\$\{converted_mid\}/g, currentMid);
              }
            }
            response.data.AdminData.app_link = replacedString;
            // if (localStorage.getItem('Modal') != 'Locked') {
            if (currentMid == mid) {
              localStorage.setItem('mid', response.data.converted_mid);
            }
            setData(response.data.data);
            setModalData(response.data.AdminData);
            setconverted_id(response.data.converted_id);
            setgameData(response.data.gameData);
            setAppLinkLocalData(response.data.App_Link_Data);
            setIsLoading(false);
            let ids = [];
            for (const key in result) {
              ids.push(result[key].id)
            }
            localStorage.setItem('data', JSON.stringify(ids))
          })
          .catch(error => {
            console.error(error);
          });
      }
    }
  }, []);

  setTimeout(() => {
    if (localStorage.getItem('back')) {
      localStorage.setItem('callJoined', true);
    }
    if (localStorage.getItem('back')) {
      localStorage.removeItem('back');
      if (localStorage.getItem('callStarted') == 'true') {
        localStorage.removeItem('callStarted');
        localStorage.removeItem('payment');
        localStorage.removeItem('waiting');
        localStorage.removeItem('startTime');
        localStorage.removeItem('callJoined');
        localStorage.removeItem('calling');
      }
    }
    console.log('timeoutRun');
  }, 1000);

  const loadMoreVideo = useCallback(() => {
    let skey = searchParams.get("key");
    let isDefault = 1;
    if (isDefault) {
      if (localStorage.getItem('data') !== undefined || localStorage.getItem('data') !== null || localStorage.getItem('data') !== '') {
        let videoids = JSON.parse(localStorage.getItem('videoids'));
        setIsLoading(false);
        let data = qs.stringify({
          'filter': videoids,
          'limit': '2'
        });

        //   let config = {
        //     method: 'post',
        //     maxBodyLength: Infinity,
        //     url: 'https://tik-tok.porn/api/new-videos',
        //     headers: {
        //       'Content-Type': 'application/x-www-form-urlencoded',
        //       'Cookie': 'XSRF-TOKEN=eyJpdiI6IklBS1BRdjh3M2thMnpSZ0xRWXhxOGc9PSIsInZhbHVlIjoiZFJ1dGg3TlB0V1VGRUh5RUliM2paYnI4RkJqdU9ZNlFvVG1pV3krUWJ3aVZmT0JLZFZxaW5mYTNlT25OYkdmT3cyZ2tVcHF6UUcvekRtclJha0g2L2Z4RHMyYVFhM01TNHhxU0FIcFlTUHZONjVKSDkrSWxZZC9MVGFFZ2pGWEsiLCJtYWMiOiI1YWU1NGU5NjQ3ZDQ4OTBkMzQwZDU3YWY4ZGI5M2ZhMTgyODY4MmNkZjhhZjg5ZTcxMDQ0ZDE2MTY2YzQwNmQ1IiwidGFnIjoiIn0%3D; tik_tokporn_session=eyJpdiI6Im5JR2llL3VGS2l0ZVV0QXJ0QS9nRUE9PSIsInZhbHVlIjoiRGl2aVZ6RWJiMkVGdStuUTJQT2tjeU1WTWRTbTBGdFRTR240K2VHeWhWYWZBZWc5SmI0V0hFQTluYlZ2OCtuVUduQmozWDhqTmFzMlFucVJMVVBrRzhWVHNGUWdieWY4R0xldjFWRDhWL2Vsa3AxbmF5dWlLcHRZZTdxaGR6UUEiLCJtYWMiOiJkYTA0NDhhN2IzYWNmMmJkYWYyYTMxMGU3NjNmODJhZTEwMjU2YWFlN2FlMDhlNDcxYTM1MGFlMzllNjYzMTMwIiwidGFnIjoiIn0%3D'
        //     },
        //     data: data
        //   };

        //   axios.request(config).then((response) => {
        //     let results = response.data;
        //     if (results.length == 0) {
        //       localStorage.removeItem('videoids')
        //       loadMoreVideo();
        //     }
        //     for (const result of results) {
        //       let obj = {
        //         id: result.video_id,
        //         username: result.username,
        //         videoUrl: result.url,
        //         isFollowed: false,
        //         title: "✨✨✨🌈😍🌈✨✨✨ :- 115",
        //         profileUrl: "https://avatars.githubusercontent.com/u/69384657?v=4",
        //         reaction: {
        //           "likes": `${result.likes}`,
        //           "comments": "1151K",
        //           "isLiked": true
        //         }
        //       }
        //       videoids.push(result.video_id)
        //       setData(prevData => [...prevData, obj]);
        //     }
        //     localStorage.setItem('videoids', JSON.stringify(videoids))
        //   }).catch((error) => {
        //     // console.log(error);
        //   });
        // } else {
        //   let videoids = localStorage.getItem('videoids') != null ? JSON.parse(localStorage.getItem('videoids')) : [];
        //   let data = qs.stringify({
        //     'filter': videoids,
        //     'limit': '2'
        //   });

        //   let config = {
        //     method: 'post',
        //     maxBodyLength: Infinity,
        //     url: 'https://tik-tok.porn/api/new-videos',
        //     headers: {
        //       'Content-Type': 'application/x-www-form-urlencoded',
        //       'Cookie': 'XSRF-TOKEN=eyJpdiI6IklBS1BRdjh3M2thMnpSZ0xRWXhxOGc9PSIsInZhbHVlIjoiZFJ1dGg3TlB0V1VGRUh5RUliM2paYnI4RkJqdU9ZNlFvVG1pV3krUWJ3aVZmT0JLZFZxaW5mYTNlT25OYkdmT3cyZ2tVcHF6UUcvekRtclJha0g2L2Z4RHMyYVFhM01TNHhxU0FIcFlTUHZONjVKSDkrSWxZZC9MVGFFZ2pGWEsiLCJtYWMiOiI1YWU1NGU5NjQ3ZDQ4OTBkMzQwZDU3YWY4ZGI5M2ZhMTgyODY4MmNkZjhhZjg5ZTcxMDQ0ZDE2MTY2YzQwNmQ1IiwidGFnIjoiIn0%3D; tik_tokporn_session=eyJpdiI6Im5JR2llL3VGS2l0ZVV0QXJ0QS9nRUE9PSIsInZhbHVlIjoiRGl2aVZ6RWJiMkVGdStuUTJQT2tjeU1WTWRTbTBGdFRTR240K2VHeWhWYWZBZWc5SmI0V0hFQTluYlZ2OCtuVUduQmozWDhqTmFzMlFucVJMVVBrRzhWVHNGUWdieWY4R0xldjFWRDhWL2Vsa3AxbmF5dWlLcHRZZTdxaGR6UUEiLCJtYWMiOiJkYTA0NDhhN2IzYWNmMmJkYWYyYTMxMGU3NjNmODJhZTEwMjU2YWFlN2FlMDhlNDcxYTM1MGFlMzllNjYzMTMwIiwidGFnIjoiIn0%3D'
        //     },
        //     data: data
        //   };

        //   axios.request(config).then((response) => {
        //     let results = response.data;
        //     let master = [];
        //     for (const result of results) {
        //       let obj = {
        //         id: result.video_id,
        //         username: result.username,
        //         videoUrl: result.url,
        //         isFollowed: false,
        //         title: "✨✨✨🌈😍🌈✨✨✨ :- 115",
        //         profileUrl: "https://avatars.githubusercontent.com/u/69384657?v=4",
        //         reaction: {
        //           "likes": `${result.likes}`,
        //           "comments": "1151K",
        //           "isLiked": true
        //         }
        //       }
        //       master.push(obj);
        //       videoids.push(result.video_id)
        //     }
        //     setData(master);
        //     localStorage.setItem('videoids', JSON.stringify(videoids))
        //   }).catch((error) => {
        //     // console.log(error);
        //   });
        // 35.153.72.251
        axios.get(`${API_BASE_URL}api/video/LoadMoreVideo/${localStorage.getItem('data')}`)
          .then(response => {
            let ids = JSON.parse(localStorage.getItem('data'));
            setIsLoading(false);
            let result = response.data.data;
            if (response.data.repeate === true) {
              localStorage.removeItem('data')
            }
            if (response.data.data[0]) {
              for (const key in result) {
                ids.push(result[key].id)
              }
              localStorage.setItem('data', JSON.stringify(ids))
              setData(prevData => [...prevData, response.data.data[0]]);
              setData(prevData => [...prevData, response.data.data[1]]);
            }
            else {
              localStorage.removeItem('data');
              loadMoreVideo();
            }
          })
          .catch(error => {
            console.error(error);
          });
        // axios.get(`${API_BASE_URL}api/video/GetVideoData?key=${key}`).then(response => {
        //   let result = response.data.data;
        //   var inputString = response.data.AdminData.app_link;
        //   var newValue = response.data.converted_id;
        //   var replacedString = inputString.replace(/\$\{converted_id\}/g, newValue);
        //   replacedString = replacedString.replace(/\$\{gameData.package_name\}/g, response.data.gameData.package_name);
        //   if (currentMid == mid) {
        //     replacedString = replacedString.replace(/\$\{converted_mid\}/g, response.data.converted_mid);
        //     localStorage.setItem('mid', response.data.converted_mid);
        //   } else {
        //     if (currentMid == '' || currentMid == undefined || currentMid == null) {
        //       localStorage.setItem('mid', response.data.converted_mid);
        //       replacedString = replacedString.replace(/\$\{converted_mid\}/g, response.data.converted_mid);
        //     } else {
        //       replacedString = replacedString.replace(/\$\{converted_mid\}/g, currentMid);
        //     }
        //   }
        //   response.data.AdminData.app_link = replacedString;
        //   // if (localStorage.getItem('Modal') != 'Locked') {
        //   if (currentMid == mid) {
        //     localStorage.setItem('mid', response.data.converted_mid);
        //   }
        //   setModalData(response.data.AdminData);
        //   setconverted_id(response.data.converted_id);
        //   setgameData(response.data.gameData);
        //   setAppLinkLocalData(response.data.App_Link_Data);
        // }).catch(error => {
        //   console.error(error);
        // });
      }
    } else {
      if (localStorage.getItem('data') !== undefined || localStorage.getItem('data') !== null || localStorage.getItem('data') !== '') {
        axios.get(`${API_BASE_URL}api/video/LoadMoreVideo/${localStorage.getItem('data')}`)
          .then(response => {
            let ids = JSON.parse(localStorage.getItem('data'));
            setIsLoading(false);
            let result = response.data.data;
            if (response.data.repeate === true) {
              localStorage.removeItem('data')
            }
            if (response.data.data[0]) {
              for (const key in result) {
                ids.push(result[key].id)
              }
              localStorage.setItem('data', JSON.stringify(ids))
              setData(prevData => [...prevData, response.data.data[0]]);
              setData(prevData => [...prevData, response.data.data[1]]);
            }
            else {
              localStorage.removeItem('data');
              loadMoreVideo();
            }
          })
          .catch(error => {
            console.error(error);
          });
      }
      else {
        axios.get(`${API_BASE_URL}api/video/GetVideoData`)
          .then(response => {
            let result = response.data.data;
            let ids = [];
            for (const key in result) {
              ids.push(result[key].id)
            }
            localStorage.setItem('data', JSON.stringify(ids))
            setData(prevData => [...prevData, response.data.data]);
          })
          .catch(error => {
            console.error(error);
          });
      }
    }

  }, []);

  const getDeviceId = () => {
    // Try to get the device ID from localStorage
    let storedDeviceId = sessionStorage.getItem('deviceId');

    // If no ID is found, generate a new one
    if (!storedDeviceId) {
      storedDeviceId = uuidv4();
    }

    setDeviceId(storedDeviceId);
    return storedDeviceId;
  };

  async function get_available_host() {
    $('#CloseModal').click();
    axios.get(`${API_BASE_URL}api/Host/getAvailableHost`)
      .then(async response => {
        let result = response.data;
        if (result.success) {
          let storedDeviceId = getDeviceId();
          console.log(storedDeviceId);
          if (localStorage.getItem('callJoined') == 'false' && localStorage.getItem('waiting') == 'true') {
            alert('You already calling a call');
          } else if (localStorage.getItem('callJoined') == 'true' && localStorage.getItem('waiting') == 'true' && localStorage.getItem('payment') == 'true') {
            localStorage.setItem('deviceId', storedDeviceId);
            sessionStorage.setItem('deviceId', storedDeviceId);
            window.location.href = '/call';
          } else if (localStorage.getItem('callJoined') == 'false') {
            alert('You already joined another call');
          } else {
            localStorage.setItem('deviceId', storedDeviceId);
            sessionStorage.setItem('deviceId', storedDeviceId);
            // localStorage.setItem('payment', true);
            // navigate('/call');
            await axios({
              method: 'POST',
              url: `${API_BASE_URL}api/payment/checkUserPayment`,
              data: {
                user_id: storedDeviceId
              }
            }).then(async checkUser => {
              if (checkUser.data.success) {
                navigate('/call');
              } else {
                await axios({
                  method: 'POST',
                  url: `${API_BASE_URL}api/payment/addUserPayment`,
                  data: {
                    user_id: storedDeviceId
                  }
                }).then(response => {
                  if (response.data.data != null) {
                    console.log(response.data, 'success');
                    console.log('payment');
                    navigate('/call');
                  } else {
                    console.log(response.data, 'error');
                  }
                }).catch(err => {
                  console.log(err);
                })
              }
            }).catch(err => {
              console.log(err);
            })
          }
        } else {
          alert("error");
        }
      })
      .catch(error => {
        console.error(error);
      });
  }

  const handleSlideChange = async (swiper) => {
    const currentIndex = swiper.activeIndex;
    const loadThreshold = data.length - 1;
    shortContainerRef.current.querySelectorAll('video').forEach(video => {
      video.pause();
    });

    document.getElementById('Video_' + currentIndex).play();
    if (currentIndex >= loadThreshold && !loadedIndexes.includes(currentIndex)) {
      await loadMoreVideo();
      setLoadedIndexes(prevIndexes => [...prevIndexes, currentIndex]);
    }
    setIsLoading(false);

    if ((currentIndex + 1) % ModalData.open_model === 0) {
      document.getElementById('Launch_Modal').click();
      localStorage.setItem('Modal', 'Locked');
      shortContainerRef.current.querySelectorAll('video').forEach(video => {
        video.pause();
      });
    }
  };
  useEffect(() => {
    if (IsLoading == false) {
      if (localStorage.getItem('Modal') == 'Locked') {
        document.getElementById('Launch_Modal').click();
        shortContainerRef.current.querySelectorAll('video').forEach(video => {
          video.pause();
        });
      }
    }
  }, [IsLoading, data])

  const CheckData = () => {
    // if (localStorage.getItem('data') == null) {
    //   setIsLoading(true)
    //   axios.get(`${API_BASE_URL}api/video/GetVideoData`)
    //     .then(response => {
    //       let ids = [];
    //       setIsLoading(false);
    //       let result = response.data.data;
    //       for (const key in result) {
    //         ids.push(result[key].id)
    //       }
    //       localStorage.setItem('data', JSON.stringify(ids))
    //       setData(prevData => [...prevData, response.data.data[0]]);
    //       setData(prevData => [...prevData, response.data.data[1]]);
    //     })
    //     .catch(error => {
    //       console.error(error);
    //     });
    // }
  }
  return (
    <>
      {IsLoading == true ? <div className='inner-slider-load'>
        <div className='loading-spinner'><Spinner animation="border" /></div> </div> : ''}
        <div className='d-flex align-items-center justify-content-center vh-100'>
          <button type="button"  onClick={() => { get_available_host() }} className="btn btn-primary">
            Call Now
          </button>
        </div>
    </>
  );
}

export default CallNow;
