import React from 'react';
import { Link } from 'react-router-dom';

const Header = (props) => {
    let Page_Name = props.page_name;
    return (
        <>
            <link rel="stylesheet" href="/assets/vehicle_info/css/style.css" />
            <link rel="stylesheet" href="/assets/vehicle_info/css/bootstrap.min.css" />
            <link rel="stylesheet" href="/assets/vehicle_info/css/bootstrap-icons.css" />
            <div className="position-fixed w-100" style={{zIndex:'99'}}>
                <nav className="navbar navbar-expand-lg p-0">
                    <div className="container">
                        <div className="navbar-logo">
                            <Link to={"/"}>
                                <img src="/assets/vehicle_info/images/logo.png" title="logo" />
                            </Link>
                        </div>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                            <ul className="navbar-nav mb-2 mb-lg-0">
                                <li className="nav-item active-menu">
                                    <Link className={`nav-link custom-font-weight ${Page_Name == "Home" ? 'Active_Nav' : ''}`} to={"/"}>Home</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={`nav-link custom-font-weight ${Page_Name == "About" ? 'Active_Nav' : ''}`} to={"/about-us"}>About</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={`nav-link custom-font-weight ${Page_Name == "Policy" ? 'Active_Nav' : ''}`} to={"/policy"}>Policy</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={`nav-link custom-font-weight ${Page_Name == "Contact" ? 'Active_Nav' : ''}`} to={"/contact"}>Contact</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </>
    )
}

export default Header;
