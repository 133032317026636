import React, { useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import axios from 'axios';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';

const Home = () => {

    const [inputValue, setInputValue] = useState('');
    const [isValid, setIsValid] = useState(true);
    const navigate = useNavigate();
    const regex = /^[A-Za-z]{2}\d{2}[A-Za-z]{2}\d{4}$/;
    const handleChange = (e) => {
        const value = e.target.value;
        setInputValue(value);
        setIsValid(regex.test(value));
    };

    const CheckVehicleInfo = (e) => {
        e.preventDefault();
        if (inputValue) {
            if (isValid == true) {

                navigate(`/vehicle-details/${inputValue}`);

                // let config = {
                //     method: 'get',
                //     maxBodyLength: Infinity,
                //     // url: `https://www.carinfo.app/_next/data/8s6x3S-daJhujYUYOjnoy/rc-details/${inputValue}.json?rc=${inputValue}`,
                //     url: `https://www.acko.com/api/seo/challanService/GJ05LX7538/`,
                //     // headers: {

                //     // }
                // };

                // axios.request(config)
                //     .then((response) => {
                //         console.log(response.data);
                //     })
                //     .catch((error) => {
                //         console.log(error);
                //     });


            } else {
                alert('Enter Valid Vehicle Number');
            }
        } else {
            alert('Enter Vehicle Number');
        }
    }

    return (
        <>
            {/* Header */}
            <Header page_name={"Home"} />

            {/* Hero Section */}
            <div className='custom-padding-top'>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-6 hero-section-content-box-main">
                            <h2>Check RC Details and Stay Updated</h2>
                            <p className="text-color-primary">CarInfo brings you all the important registration details of any vehicle in India.</p>
                            <div className="col-12 col-md-10 col-lg-12">
                                <form action="">
                                    <div className="form-div mt-4">
                                        <label htmlFor="">Enter vehicle number to get details</label>
                                        <div className="d-flex flex-wrap hero-section-content-box align-items-center mt-3">
                                            <div className="col-1 col-md-1">
                                                <img src="/assets/vehicle_info/images/india_img.svg" alt="" />
                                            </div>
                                            <div className="col-11 col-md-10">
                                                <input type="text" placeholder="EX.GJ05PQ35XX"
                                                    value={inputValue}
                                                    onChange={handleChange}
                                                    className="form-control fw-bold fs-5 rounded-pill input-number" />
                                            </div>
                                            <div className="col-12 col-md-1 ps-1 mt-3 mt-md-0">
                                                <button type="submit" className="btn btn-primary rounded-pill fw-bold fs-5 search-btn" onClick={(e) => CheckVehicleInfo(e)}><i
                                                    className="bi bi-search"></i></button>
                                            </div>
                                            {!isValid && <p style={{ color: 'red', fontSize: "12px" }}>Enter valid plate number</p>}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="d-none d-lg-block col-lg-6 hero-section-img">
                            <img src="/assets/vehicle_info/images/hero_section.svg" alt="" className="w-100 h-100" />
                        </div>
                    </div>
                </div>
            </div>

            {/* Vehicle Details Modal */}
            <button type="button" className="btn btn-primary d-none" id='vehicle_modal_btn' data-bs-toggle="modal" data-bs-target="#vehicle_modal" data-bs-whatever="@mdo">Open modal for @mdo</button>

            <div className="modal fade modal-xl" id="vehicle_modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Bike/Car Details</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <h2>Hello</h2>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Footer */}
            <Footer />
        </>
    )
}

export default Home;
