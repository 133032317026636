import { io } from "socket.io-client";
import React, { useCallback, useEffect, useRef, useState } from 'react';
import AgoraRTC from 'agora-rtc-sdk-ng';
import ReactDOM from 'react-dom';
import { AGORA_APP_ID, API_BASE_URL, BASE_URL } from '../config';
import { MdCallEnd, MdMicOff, MdOutlineVideocam } from 'react-icons/md';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import moment from 'moment';
import randn from 'randn';
import randomstring from 'randomstring';
// const client = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' });

// const WebCall = ({client, userId, setUserId}) => {
const WebCall = ({ client }) => {

  // const socket = io("http://localhost:9000", {
  //   transports: ['websocket'],
  //   reconnection: true,
  //   reconnectionAttempts: 10
  // });

  const socket = io("https://api.omegames.in", {
    transports: ['websocket'],
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: 10,
    timeout: 30000 // Timeout set to 30 seconds
  });

  socket.on('connect', async () => {
      // await logger.info("Connected to game remote server");
      console.log('Connected to game remote server');
  });

  socket.on("connect_error", (err) => {

    console.log(err.code);
    // the reason of the error, for example "xhr poll error"
    console.log(err.message, 'socket connection error');
  
    // some additional description, for example the status code of the initial HTTP response
    console.log(err.description, 'socket connection error');
  
    // some additional context, for example the XMLHttpRequest object
    console.log(err.context, 'socket connection error');

    setTimeout(() => {
      console.log('Retrying connection...');
      socket.connect();
    }, 1000); // Retry after 3 seconds
  });

  const [joined, setJoined] = useState(false);
  const [userJoined, setUserJoined] = useState(false);
  const [localTrack, setLocalTrack] = useState({ audioTrack: null, videoTrack: null });
  const [isMuted, setIsMuted] = useState(false);
  const [isVideoOff, setIsVideoOff] = useState(false);
  const [channelName, setChannelName] = useState(null);
  const [hostData, setHostData] = useState(null);
  const [userId, setUserId] = useState(null);
  const [ringing, setRinging] = useState(null);
  const [adminCallDuration, setAdminCallDuration] = useState(60000);
  const remoteContainer = useRef(null);
  const navigate = useNavigate();
  const [callDuration, setCallDuration] = useState(0);
  const [loaderText, setLoaderText] = useState('Calling');
  const [hasAnsweredCall, setHasAnsweredCall] = useState(false);
  const [uid, setUID] = useState(0);

  useEffect(() => {
    // Make sure this is only attached once
    const handleCallStatusUpdate = (data) => {
      let user_id = data.user_id;
      let messageData = data.message;
      if(user_id == sessionStorage.getItem('deviceId')){
        if (ringing) {
          clearTimeout(ringing);
          setRinging(null);
        }
        client.leave().then(async () => {
          console.log('client Leave', 'handleCallStatusUpdate');
          setJoined(false);
          setUserJoined(false);
          setLocalTrack({ audioTrack: null, videoTrack: null });
          // clearTimeout(disconnectTimeout);
          // clearTimeout(disconnectCallDuration);
        });
        // setTimeout(() => {
          setJoined(false);
          console.log(channelName, 'channelName socket diconnected');
          if (channelName != null) {
            setChannelName(null);
          } else if (channelName != false) {
            setChannelName(false);
          } else if (channelName != null && channelName != false) {
            setChannelName(null);
          }
          setLoaderText('Calling');
          // alert('Host not Answering Call! Please Wait, We are Redirecting Call to Another Host');
        // }, 100);
        // console.log(channelName, 'channelName call disconnected');
        // if (channelName != null) {
        //   setChannelName(null);
        // } else if (channelName != false) {
        //   setChannelName(false);
        // } else if (channelName != null && channelName != false) {
        //   setChannelName(null);
        // }
        // setLoaderText('Calling');
      }
    };

    socket.on('call_disconnected', handleCallStatusUpdate);

    // Cleanup on unmount
    return () => {
      socket.off('call_disconnected', handleCallStatusUpdate);
    };
  }, []);

  useEffect(() => {
    // Make sure this is only attached once
    const handleCallUpdate = (data) => {
      console.log('socket emited');
      let user_id = data.user_id;
      let host_id = data.host_id;
      console.log(user_id, 'handleCallUpdateStatus');
      console.log(sessionStorage.getItem('deviceId'));
      console.log(hostData, 'socket host Data');
      console.log(host_id);
      // let messageData = data.message;
      if(user_id != sessionStorage.getItem('deviceId') && host_id == hostData.id){
        if (ringing) {
          clearTimeout(ringing);
          setRinging(null);
        }
        client.leave().then(async () => {
          console.log('client Leave', 'handleCallUpdate');
          setJoined(false);
          setUserJoined(false);
          setLocalTrack({ audioTrack: null, videoTrack: null });
          // clearTimeout(disconnectTimeout);
          // clearTimeout(disconnectCallDuration);
        });
        // setTimeout(() => {
          console.log(channelName, 'channelName socket diconnected');
          setJoined(false);
          if (channelName != null) {
            setChannelName(null);
          } else if (channelName != false) {
            setChannelName(false);
          } else if (channelName != null && channelName != false) {
            setChannelName(null);
          }
          setLoaderText('Calling');
          // alert('Host not Answering Call! Please Wait, We are Redirecting Call to Another Host!');
        // }, 100);
        // console.log(channelName, 'channelName call disconnected');
        // if (channelName != null) {
        //   setChannelName(null);
        // } else if (channelName != false) {
        //   setChannelName(false);
        // } else if (channelName != null && channelName != false) {
        //   setChannelName(null);
        // }
        // setLoaderText('Calling');
      }
    };

    // if(hostData){
      socket.on('call_started', handleCallUpdate);
    // }

    // Cleanup on unmount
    return () => {
      socket.off('call_started', handleCallUpdate);
    };
  }, [hostData]);

  useEffect(() => {
    if (!localStorage.getItem('deviceId')) {
      console.log('device id not found');
      window.location.href = `${BASE_URL}view-shorts`;
      // navigate(`/view-shorts`);
    } else {
      if (localStorage.getItem('callStarted') == 'true') {
        if (joined) {
          client.leave().then(async () => {
            let data = await userEndCall();
            if (data) {
              console.log('callStarted True');
              window.location.href = `${BASE_URL}view-shorts`;
              // setJoined(false);
              // setUserJoined(false);
              // setLocalTrack({ audioTrack: null, videoTrack: null });
              // clearTimeout(disconnectTimeout);
              // clearTimeout(disconnectCallDuration);  // Clear the call duration timer
              // navigate(`/view-shorts`);
            }
          });
        }
      } else {
        localStorage.setItem('waiting', true);
        localStorage.setItem('calling', false);
        localStorage.setItem('payment', true);
        localStorage.setItem('callJoined', false);
        setUserId(sessionStorage.getItem('deviceId'));
        socket.emit('join', {user_id: sessionStorage.getItem('deviceId')})
        console.log('callcszfsafsafsedfsefewrwew');
        localStorage.removeItem('deviceId');
      }
    }
    getAdminSetting();
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.ctrlKey && event.key == 'r') || (event.ctrlKey && event.shiftKey && event.key == 'r') || event.key == 'F5') {
        event.preventDefault();
      }
    };

    const handleBeforeUnload = (event) => {
      localStorage.clear();
      if (ringing) {
        clearTimeout(ringing);
        setRinging(null);
      }
      console.log('BeforeUnload');
      window.location.href = `${BASE_URL}view-shorts`;
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('beforeunload', handleBeforeUnload, { capture: true });

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {

    const handlePopstate = (event) => {
      localStorage.clear();
      if (ringing) {
        clearTimeout(ringing);
        setRinging(null);
      }
      console.log('Popstate');
      window.location.href = `${BASE_URL}view-shorts`;
    };

    window.addEventListener('popstate', handlePopstate, { capture: true });

    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);

  async function checkHostAvilable() {
    const response = await fetch(`${API_BASE_URL}api/Host/checkHostAvilable`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ host_id: hostData.id })
    });
    const data = await response.json();
    return data;
  }

  async function changeHostStatus() {
    const response = await fetch(`${API_BASE_URL}api/Host/online`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ host_id: hostData.id, status: 1 })
    });
    const data = await response.json();
    return data;
  }

  const delayedQuery = useCallback(
    debounce((q) => sendQuery(q), 300),
    []
  );

  const sendQuery = useCallback(
    async (channelName) => {
      if (ringing) {
        clearTimeout(ringing);
      }
      if (channelName !== null && channelName != false) {
        console.log(channelName);
        setChannelName(channelName);
        return;
      };

      try {
        let response = await fetch(`${API_BASE_URL}api/host/getAvailableHost`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        });
        const data = await response.json();
        if (data.success) {
          let responseData = data.data;
          if (responseData.length > 0) {
            var item = responseData[Math.floor(Math.random() * responseData.length)];
            setHostData(item);
            setLoaderText('Ringing');
            let channel_name = await randomstring.generate({
              length: 12,
              charset: 'alphabetic'
            });
            console.log(channel_name);
            setChannelName(channel_name);
          } else {
            if (ringing) {
              clearTimeout(ringing);
              setRinging(null);
            }
            setLoaderText('Please wait, all executives are busy. They will join you soon.');
            if (channelName == null) {
              setChannelName(false);
            }
            if (channelName == false) {
              setChannelName(null);
            }
            if (channelName != null && channelName != false) {
              setChannelName(null);
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
  );

  console.log(hostData, 'hostData');

  useEffect(() => {
    delayedQuery(channelName);
  }, [channelName, delayedQuery]);

  async function getAdminSetting() {
    const response = await fetch(`${API_BASE_URL}api/admin/getAdminSetting`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
    const data = await response.json();
    if (data.success) {
      let responseData = data.data;
      let miliSecond = responseData.call_seconds * 1000;
      setAdminCallDuration(miliSecond);
    }
  }

  async function hostAnswerCall() {
    console.log(hostData, 'hostAnswerCall');
    console.log(userId, 'hostAnswerCallUserId');
    if (!hasAnsweredCall) {
      const response = await fetch(`${API_BASE_URL}api/Host/hostAnswerCall`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ user_id: userId, host_id: hostData.id, call_status: '1' })
      });
      const data = await response.json();
      if (data.success) {
        localStorage.setItem('startTime', moment().toISOString());
        if(ringing){
          clearTimeout(ringing);
        }
        setHasAnsweredCall(true);
      }
    }
  }

  async function userEndCall(date2 = null) {
    let start = localStorage.getItem('startTime');
    if (date2 == null) {
      date2 = moment(moment().toISOString());
    }
    let seconds = date2.diff(moment(start), 'seconds')
    let end_duration = seconds * 1000;
    let time = moment().format('DD/MM/yyyy hh:mm a');
    const response = await fetch(`${API_BASE_URL}api/Host/userEndCall`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ user_id: userId, host_id: hostData.id, end_duration: end_duration, time: time })
    });
    const data = await response.json();
    localStorage.removeItem('callStarted');
    localStorage.removeItem('waiting');
    localStorage.removeItem('payment');
    localStorage.removeItem('callJoined');
    localStorage.setItem('calling', true);
    // setUserId(null);
    return data;
  }

  useEffect(() => {
    const handleUserPublished = async (user, mediaType) => {
      console.log(uid, 'check Uid');
      console.log(user.uid);
      if(uid == user.uid){
        console.log(user, 'user Published');
        await client.subscribe(user, mediaType);
        let checkHostAvilableData = await checkHostAvilable();
        if (checkHostAvilableData.success) {
          setUserJoined(true);
          hostAnswerCall();
          localStorage.removeItem('waiting');
          console.log(mediaType, 'User Joined Video Call');
          localStorage.setItem('callStarted', true);
          if (mediaType === 'video' || mediaType === 'all') {
            const remoteVideoTrack = user.videoTrack;
            const remotePlayer = document.createElement('div');
            remotePlayer.id = `remote-player-${user.uid}`;
            remotePlayer.style.width = '350px';
            remotePlayer.style.height = '90vh';
            remotePlayer.style.margin = 'auto';
            remotePlayer.style.backgroundColor = '#fff';
            remotePlayer.style.position = 'relative';
            $(`#remote-player-${user.uid}`).remove();
            remoteContainer.current.append(remotePlayer);
            remoteVideoTrack.play(remotePlayer);
          }
          if (mediaType === 'audio' || mediaType === 'all') {
            if (document.getElementById(`Muted-${user.uid}`)) {
              const remotePlayer = document.getElementById(`Muted-${user.uid}`);
              remotePlayer.remove();
            }
            console.log(user.audioTrack, 'audioTrack');
            const remoteAudioTrack = user.audioTrack;
            remoteAudioTrack.play();
          }
        } else {
          if(ringing){
            clearTimeout(ringing);
          }
          setChannelName(null);
        }
      }
    };
  
    // Add the event listener
    client.on('user-published', handleUserPublished);
  
    // Clean up the event listener on unmount
    return () => {
      client.off('user-published', handleUserPublished);
    };
  }, [hostData, uid]);
  

  let disconnectTimeout;
  let disconnectCallDuration;

  useEffect(() => {
    let isMounted = true;

    console.log(channelName, 'send Request');

    const initAgora = async () => {
      console.log(channelName, 'send Request initAgora Mounted');
      console.log(client.connectionState);
      console.log(isMounted);

      // if(channelName){
      //   client.leave().then(async () => {
      //     setJoined(false);
      //     setUserJoined(false);
      //     setLocalTrack({ audioTrack: null, videoTrack: null });
      //     // clearTimeout(disconnectTimeout);
      //     // clearTimeout(disconnectCallDuration);
      //   });
      // }
      
      if (!isMounted) {
        return;
      }
      // if (client.connectionState == 'DISCONNECTED') {
      //   if (!isMounted || client.connectionState !== 'DISCONNECTED') {
      //     if (channelName == null) {
      //       setChannelName(false);
      //     }
      //     if (channelName == false) {
      //       setChannelName(null);
      //     }
      //     if (channelName != null && channelName != false) {
      //       setChannelName(null);
      //     }
      //     return;
      //   }
      // } else if (client.connectionState == 'CONNECTED') {
      //   if (!isMounted || client.connectionState !== 'CONNECTED') {
      //     if (channelName == null) {
      //       setChannelName(false);
      //     }
      //     if (channelName == false) {
      //       setChannelName(null);
      //     }
      //     if (channelName != null && channelName != false) {
      //       setChannelName(null);
      //     }
      //     return;
      //   }
      // } else {
      //   if (!isMounted || client.connectionState !== 'DISCONNECTED') {
      //     if (channelName == null) {
      //       setChannelName(false);
      //     }
      //     if (channelName == false) {
      //       setChannelName(null);
      //     }
      //     if (channelName != null && channelName != false) {
      //       setChannelName(null);
      //     }
      //     return;
      //   }
      // }

      console.log(channelName, 'send Request initAgora');

      try {

        // client.on('user-published', async (user, mediaType) => {
        //   await client.subscribe(user, mediaType);
        //   // let checkHostAvilableData = await checkHostAvilable();
        //   // if (checkHostAvilableData.success) {
        //     setUserJoined(true);
        //     hostAnswerCall();
        //     localStorage.removeItem('waiting');
        //     console.log('User Joined Video Call');
        //     localStorage.setItem('callStarted', true);
        //     if (mediaType === 'video' || mediaType === 'all') {
        //       const remoteVideoTrack = user.videoTrack;
        //       const remotePlayer = document.createElement('div');
        //       remotePlayer.id = `remote-player-${user.uid}`;
        //       remotePlayer.style.width = '350px';
        //       remotePlayer.style.height = '90vh';
        //       remotePlayer.style.margin = 'auto';
        //       remotePlayer.style.backgroundColor = '#fff';
        //       remotePlayer.style.position = 'relative';
        //       $(`#remote-player-${user.uid}`).remove();
        //       remoteContainer.current.append(remotePlayer);
        //       remoteVideoTrack.play(remotePlayer);
        //     } else if (mediaType === 'audio' || mediaType === 'all') {
        //       if (document.getElementById(`Muted-${user.uid}`)) {
        //         const remotePlayer = document.getElementById(`Muted-${user.uid}`);
        //         remotePlayer.remove();
        //       }
        //       const remoteAudioTrack = user.audioTrack;
        //       remoteAudioTrack.play();
        //     }
        //   // } else {
        //   //   setChannelName(null);
        //   // }
        // });

        client.on('user-unpublished', async (user, mediaType) => {
          if (mediaType === 'video') {
            const remotePlayer = document.getElementById(`remote-player-${user.uid}`);
            if (remotePlayer) {
              remotePlayer.remove();
            }
          }

          if (mediaType === 'audio') {
            if (!document.getElementById(`Muted-${user.uid}`)) {
              const remotePlayer = document.getElementById(`remote-player-${user.uid}`);
              const NewDiv = document.createElement('div');
              NewDiv.id = `Muted-${user.uid}`;
              const NewButton = document.createElement('button');
              NewButton.className = `call-view__controls__icon-btn`;
              NewButton.style.color = '#fff';
              NewButton.style.position = 'absolute';
              NewButton.style.top = '15px';
              NewButton.style.right = '15px';
              ReactDOM.render(<MdMicOff style={{ color: '#FF3346' }} />, NewButton);
              NewDiv.append(NewButton);
              remotePlayer.append(NewDiv);
            }
          }
        });

        client.on('user-left', async (user) => {
          const remotePlayer = document.getElementById(`remote-player-${user.uid}`);
          if (remotePlayer) {
            remotePlayer.remove();
          }
          // let date2 = moment(moment().toISOString());
          // let data = await userEndCall(date2);
          // if(data){
          console.log('user Left');
          window.location.href = `${BASE_URL}view-shorts`;
          // setJoined(false);
          // setUserJoined(false);
          // setLocalTrack({ audioTrack: null, videoTrack: null });
          // clearTimeout(disconnectTimeout);
          // clearTimeout(disconnectCallDuration);  // Clear the call duration timer
          // navigate(`/view-shorts`);
          // }
        });
        
        console.log(client.connectionState, 'client connectionState');
        console.log(joined);

        if(!joined){
          if (channelName != null && channelName != false) {
            const uid = randn(5);
            console.log(uid);
            setUID(uid);
            console.log(channelName, 'send Request Notification');
            const notification = await fetch(`${API_BASE_URL}VideoCall`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ channelName: channelName, token: hostData.device_token, userId: userId, hostId: hostData.id, uid: uid})
            });
            const notificationData = await notification.json();
            console.log(notificationData, 'notificationData');
            if(ringing){
              clearTimeout(ringing);
            }
            if (notificationData.error) {
              setChannelName(null);
            } else {
              setRinging(setTimeout(() => {
                changeHostStatus();
                console.log(channelName, 'channelName');
                if (channelName != null) {
                  setChannelName(null);
                } else if (channelName != false) {
                  setChannelName(false);
                } else if (channelName != null && channelName != false) {
                  setChannelName(null);
                }
                setLoaderText('Calling');
                setJoined(false);
                // alert('Host not Answering Call! Please Wait, We are Redirecting Call to Another Host');
              }, 30000));
            }
            const response = await fetch(`${API_BASE_URL}generate-token`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ channelName: channelName, userId: uid })
            });
            const data = await response.json();
            const token = data.token;
  
            if(client.connectionState == 'CONNECTED'){
              console.log('client leave')
              client.leave().then(async () => {
                setJoined(false);
                setUserJoined(false);
                clearTimeout(ringing);
              });
            }
  
            console.log(channelName, 'client Join');
            await client.join(AGORA_APP_ID, channelName, token, null);
            const [audioTrack, videoTrack] = await AgoraRTC.createMicrophoneAndCameraTracks();
            // const audioTrack = await AgoraRTC.createMicrophoneAudioTrack();
            // const videoTrack = await AgoraRTC.createCameraVideoTrack();
            // const [audioTrack, videoTrack] = await AgoraRTC.createMicrophoneAndCameraTracks();
            setLocalTrack({ audioTrack, videoTrack });
            await client.publish([audioTrack, videoTrack]);
            setJoined(true);
          }
        }

      } catch (error) {
        console.error('Failed to join channel:', error);
      }
    };

    initAgora();

    // return () => {
    //   isMounted = false;
    //   // if (joined && userJoined) {
    //     client.leave().then(async () => {
    //       // let data = await userEndCall();
    //       // if (data) {
    //         console.log('client leave useEffect');
    //         // window.location.href = `${BASE_URL}view-shorts`;
    //         setJoined(false);
    //         setUserJoined(false);
    //         setLocalTrack({ audioTrack: null, videoTrack: null });
    //         clearTimeout(disconnectTimeout);
    //         clearTimeout(disconnectCallDuration);  // Clear the call duration timer
    //         // console.log('client leave useEffect');
    //         // window.location.href = `${BASE_URL}view-shorts`;
    //         // navigate(`/view-shorts`);
    //       // }
    //     });
    //   // }
    // };
  }, [joined, channelName]);

  // Set timeout to disconnect after 60 seconds
  if (userJoined) {

    console.log(ringing, 'ringing');
    if (ringing) {
      clearTimeout(ringing);
    }

    // Start the call duration timer
    disconnectCallDuration = setTimeout(() => {
      setCallDuration(callDuration + 1);
    }, 1000);

    disconnectTimeout = setTimeout(() => {
      handleLeave();
    }, adminCallDuration);
  }

  const handleMute = async () => {
    if (localTrack.audioTrack) {
      localTrack.audioTrack.setEnabled(isMuted);
      setIsMuted(!isMuted);
    }
  };

  const handleVideoOff = () => {
    if (localTrack.videoTrack) {
      localTrack.videoTrack.setEnabled(isVideoOff);
      setIsVideoOff(!isVideoOff);
    }
  };
  const handleLeave = () => {
    clearTimeout(disconnectTimeout);
    clearTimeout(disconnectCallDuration);  // Clear the call duration timer
    client.leave().then(async () => {
      let data = await userEndCall();
      if (data) {
        console.log('handleLeave');
        window.location.href = `${BASE_URL}view-shorts`;
        // setJoined(false);
        // setUserJoined(false);
        // setLocalTrack({ audioTrack: null, videoTrack: null });
        remoteContainer.current.innerHTML = '';  // Clear remote users on leave
        // navigate(`/view-shorts`);
      }
    });
  };

  const formatDuration = (duration) => {
    const minutes = Math.floor(duration / 60);
    const seconds = duration % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <>
      {userJoined ?
        <div className="call-view">
          <div className="call-view__controls-container">
            <div className="call-view__controls">
              <button id="btn--end-call" className="call-view__controls__icon-btn important" onClick={handleLeave}>
                <MdCallEnd style={{ color: '#FAFAFA' }} />
              </button>
              <button id="btn--toggle-mic" className="call-view__controls__icon-btn" onClick={handleMute}>
                <MdMicOff style={{ color: isMuted ? '#FF3346' : '#000' }} />
              </button>
              <button id="btn--toggle-cam" className="call-view__controls__icon-btn" onClick={handleVideoOff}>
                <MdOutlineVideocam style={{ color: isVideoOff ? '#FF3346' : '#000' }} />
              </button>
              <div className="call-view__controls__duration">
                {formatDuration(callDuration)}
              </div>
            </div>
          </div>
          {joined && localTrack.videoTrack && (
            <div className="call-view__tracks__local-track-container">
              <div className="call-view__tracks__local-track">
                <video
                  autoPlay
                  playsInline
                  ref={(element) => {
                    if (element && localTrack.videoTrack) {
                      localTrack.videoTrack.play(element);
                    }
                  }}
                ></video>
              </div>
            </div>
          )}
        </div> :
        <div className='vh-100 w-100 d-flex align-items-center justify-content-center flex-column gap-2'>
          <div className='loader'></div>
          <p>{loaderText && loaderText}</p>
        </div>
      }
      <div className="call-view__tracks" ref={remoteContainer}></div>
    </>
  );
};

export default WebCall;